.case-card {
  border: 0;
  box-shadow: 0px 1px 16px rgba($black, 0.15);
  border-radius: 1.25em;
  overflow: hidden;
  max-width: 19em;
  margin: 0 auto 2.5em;
  color: $gray38;
  text-align: left;
  font-size: 1rem;
  .card-body {
    padding: 1.75em 1.75em 1.5em;
  }
  .card-title {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 1em;
  }
  .card-text {
    min-height: 3em;
  }
  .card-link {
    .ic {
      font-size: 1.5em;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
}
.case-slider {
  .item {
    padding: 0 1em;
  }
  .slick-dots {
    @include list-reset;
    text-align: center;
    li {
      display: inline-block;
      vertical-align: middle;
      button {
        @include btn-reset;
        @include txt-hide;
        width: 2.5em;
        height: 2.5em;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 1em;
          height: 1em;
          margin: -0.5em 0 0 -0.5em;
          border-radius: 50%;
          background: $white;
          box-shadow: inset 2px 4px 6px rgba($black, 0.25);
        }
      }
    }
    li.slick-active {
      button {
        &::before {
          background: $primary;
          box-shadow: none;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {  
}
@include media-breakpoint-up(md) {
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
}
@include media-breakpoint-up(xxl) {
}