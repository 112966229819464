.btn {
  &.p-lg {
    padding: 1em 4em;
  }
  &.p-md {
    padding: 1em 1.5em;
  }
  &.gotop {
    color: $black;
    border-width: 2px;
    &:hover {
      color: $white;
    }
  }
  &.consult {
    letter-spacing: 0.11em;
  }
  &.contact {
    display: block;
    .ic {
      font-size: 1.75em;
      vertical-align: middle;
      margin-right: 0.25em;
      margin-top: -0.2em;
      margin-left: -0.15em;
    }
  }
}