.footer {
  background: $gray6A;
  color: $white;
  text-align: center;
  position: relative;
  padding: 1em 0;
  &::before {
    content: '';
    display: block;
    background: url('../img/footer_top.png') no-repeat 50% 100%;
    background-size: 100% auto;
    height: 110px;
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin-bottom: -1px;
  }
  .main-title {
    margin-bottom: 0.75em;
  }
  &-logo {
    margin-bottom: 2em;
    img {
      width: 220px;
    }
  }
  &-siteinfo {
    @include list-reset;
    margin-bottom: 2em;
    text-align: left;
    display: inline-block;
    text-transform: uppercase;
    li {
      position: relative;
      padding-left: 2.5em;
      margin-bottom: 1em;
    }
    .ic {
      position: absolute;
      left: 0;
      top: 0;
      color: $gray99;
      font-size: 1.75em;
    }
    a {
      color: inherit;
      word-break: break-all;
      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }
  }
  &-follow {
    margin-bottom: 1em;
    a {
      display: inline-block;
      margin-right: 0.5em;
      background: $gray99;
      color: $white;
      border-radius: 50%;
      overflow: hidden;
      line-height: 1;
      font-size: 3em;
      letter-spacing: 0;
      &.facebook {
        &:hover {
          background: rgb(24, 119, 242);
        }
      }
      &.messenger {
        &:hover {
          background: #7150FF;
        }
      }
      &.instagram {
        &:hover {
          background: #c32aa3;
        }
      }
    }
  }
  &-copyright {
    text-align: center;
    font-size: 0.9em;
    padding: 1em 0;
    text-transform: uppercase;
  }
}


@include media-breakpoint-up(sm) {  
}
@include media-breakpoint-up(md) {
  .footer {
    text-align: left;
    padding-top: 3em;
    .main-title {
      margin-bottom: 1em;
    }
    &-logo {
      padding-top: 1.5em;
    }
  }
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
  .footer-logo {
    img {
      width: 280px;
    }
  }
}
@include media-breakpoint-up(xxl) {
  .footer {
    &::before {
      background-size: 100% 100%;
    }
  }
}