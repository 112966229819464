.team-main {
  background: url('../img/team_main_bg.png') 100% 100% no-repeat;
  background-size: 45% auto;
}
.team-card {
  border: 0;
  background: $white url('../img/team_card_bg.png') 100% 0 no-repeat;
  background-size: 100% auto;
  border-radius: 2.5em;
  box-shadow: 0px 1px 16px rgba($black, 0.15);
  text-align: center;
  color: $gray38;
  letter-spacing: 0.05em;
  max-width: 18em;
  margin: 0 auto 2em;
  .card-body {
    padding: 1.25em 2em 2.5em;
  }
  .card-title {
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.1em;
    strong {
      display: inline-block;
      position: relative;
      top: 0.1em;
      margin-right: 0.2em;
      color: $primary;
      font-size: 2.3em;
      font-weight: 900;
    }
  }
  .card-subtitle {
    color: $primary;
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin: 1em 0;
  }
  .card-text {
    max-width: 12em;
    text-align: left;
    margin: 0 auto;
  }
}


@include media-breakpoint-up(sm) {  
}
@include media-breakpoint-up(md) {
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
  .team-card {
    font-size: 1.2em;
  }
}
@include media-breakpoint-up(xxl) {
}