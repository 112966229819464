.main {
  &-top {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 300px;
    text-align: center;
    background: 50% 50% no-repeat;
    background-size: cover;
    padding-top: 72px;
    .container {
      flex: 1;
    }
    &-title {
      font-size: 2em;
      font-weight: 700;
      color: $white;
      text-shadow: 0px 4px 5px rgba($black, 0.25);
      small {
        display: block;
        color: $primary;
        font-size: 0.66em;
        font-weight: 400;
        letter-spacing: -0.05em;
        text-transform: uppercase;
        margin-top: 0.25em;
      }
    }
  }
  &-content {
    padding-bottom: 18%;
    &-foot {
      padding-top: 3em;
    }
  }
  &-title {
    color: $gray62;
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 1em;
    letter-spacing: 0.05em;
    span {
      display: inline-block;
      border-top: 2px solid $primary;
      padding: 0.25em 0;
    }
    &.text-white {
      span {
        border-top-color: $white;
      }
    }
  }
  &-nav {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    &-list {
      flex-wrap: nowrap;
      justify-content: space-around;
      font-weight: 700;
      padding: 0 0.5em;
      .nav-item {
        padding: 0 0.5em;
      }
      .nav-link {
        padding: 1em 0;
        color: $white;
        border-top: 3px solid transparent;
        &:hover {
          color: $primary;
        }
        &.active {
          border-top-color: $primary;
        }
      }
    }
  }
}
.arrow-slider {
  padding: 0 40px;
  .slick-prev,
  .slick-next {
    @include btn-reset;
    @include txt-hide;
    background: $grayC4 url('../img/slider_arrows.png') no-repeat;
    background-size: 200% 100%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      background-color: $primary;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
    background-position: 100% 0;
  }
}

@include media-breakpoint-up(sm) {
  .main {
    &-nav {
      &-list {
        justify-content: center;
        .nav-item {
          padding: 0 1.2em;
        }
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .main {
    &-top {
      min-height: 400px;
    }
    &-content {
      padding-bottom: 12%;
    }
  }
}
@include media-breakpoint-up(lg) {
  .main {
    &-top {
      padding-top: 105px;
      min-height: 500px;
      &-title {
        font-size: 2.5em;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .main {
    &-title {
      font-size: 2em;
      margin-bottom: 1.5em;
    }
  }
  .arrow-slider {
    padding: 0 60px;
    .slick-prev,
    .slick-next {
      width: 60px;
      height: 60px;
    }
    .slick-prev {
      left: -30px;
    }
    .slick-next {
      right: -30px;
    }
  }
}
@include media-breakpoint-up(xxl) {
  .main {
    &-content {
      padding-bottom: 10em;
    }
  }
}