.wrapper {
  overflow: hidden;
}
.ic {
  display: inline-block;
  vertical-align: 0;
}
img {
  max-width: 100%;
}
.bd-0 {
  border: 0 !important;
}

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color !important;
  }
  .bg-#{$name} {
    background-color: $color;
    color: map-get($colors-text, $name);
  }
}

.hr-primary {
  border-top-color: $primary;
}

@each $breakpoint, $width in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    .fzr-#{$breakpoint}-18 {
      @include fzr(18);
    }
    .fzr-#{$breakpoint}-20 {
      @include fzr(20);
    }
  }
}

// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }
// @include media-breakpoint-up(xxl) {
// }