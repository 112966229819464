.breadcrumb {
    background: none;
    padding: 2em 1em;
}
.breadcrumb-item {
    + .breadcrumb-item {
        &::before {
            content: '\e908';
            @include icon-font;
            float: none;
            font-size: 0.8em;
        }
    }
    a {
        color: $gray62;
        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }
    &:last-child {
        a {
            color: $gray99;
        }
    }
}


@include media-breakpoint-up(sm) {  
}
@include media-breakpoint-up(md) {
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
}
@include media-breakpoint-up(xxl) {
}