.solution-main {
  background: url('../img/solution_main_bg.png') no-repeat 50% 100%;
  background-size: 60% auto;
}
.solution-jumbotron {
  padding-top: 0;
  padding-bottom: 3em;
  background: $grayF9 url('../img/solution_sect_bg.png') no-repeat 80% 100%;
  background-size: auto 90%;
  .jumbotron-heading {
    color: $gray62;
    font-size: 1.75em;
    font-weight: 700;
    letter-spacing: 0.05em;
    margin-bottom: 0.5em;
  }
  .lead {
    color: $grayB2;
    font-size: 1.25em;
    letter-spacing: 0.05em;
  }
}
.solution-row {
  .item {
    padding-bottom: 2em;
  }
}
.solution-card {
  border: 0;
  background: none;
  text-align: center;
  .card-pic {
    margin: 0 auto 1em;
    img {
      width: 6em;
    }
  }
  .card-body {
    border: 1px solid $gray62;
    border-width: 1px 0 0;
  }
  .card-title {
    color: $primary;
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 0.5em;
  }
}

@include media-breakpoint-up(sm) {
  .solution-jumbotron {
    margin-bottom: 3em;
  }
  .solution-row {
    .item {
      padding-bottom: 3em;
    }
  }
}
@include media-breakpoint-up(md) {
  .solution-jumbotron {
    margin-bottom: 6em;
    .jumbotron-inner {
      padding: 1.5em 0;
    }
    .lead {
      max-width: 27em;
      margin: 0 auto;
    }
  }
  .solution-row {
    .item {
      padding-bottom: 4em;
    }
  }
  .solution-card {
    display: table;
    height: 100%;
    > * {
      display: table-cell;
    }
    .card-pic {
      width: 25%;
      vertical-align: middle;
      padding-right: 1.5em;
    }
    .card-body {
      text-align: left;
      padding: 0 0 0 2em;
      border-width: 0 0 0 1px;
    }
  }
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
  .solution-jumbotron {
    .jumbotron-inner {
      font-size: 1.25em;
    }
  }
  .solution-card {
    font-size: 1.25em;
  }
}
@include media-breakpoint-up(xxl) {
}