.about-main {
  &.has-bg {
    background: url('../img/about_main_bg.png') 50% 100% no-repeat;
    background-size: 150% auto;
  }
}
.about-content {
  text-align: center;
}
.about {
  &-video {
    margin-bottom: 3em;
  }
  &-logo {
    img {
      width: 3.5em;
    }
  }
  &-title {
    color: $gray38;
    @include fz(90,40);
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 0.75em 0 0.5em;
    span {
      display: inline-block;
    }
  }
  &-desc {
    max-width: 23em;
    margin: 0 auto;
  }
}
.about-card {
  border: 0;
  border-radius: 1em;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin: 0 auto 2em;
  .card-body {
    padding: 1.5em;
  }
  .card-title {
    color: $gray38;
    @include fz(5,3);
    font-weight: 700;
    letter-spacing: 0.05em;
    small {
      @include fz(3,5);
      font-weight: 400;
      letter-spacing: 0.05em;
      margin-top: 0.2em;
    }
  }
}
.milestone-map {
  padding: 8% 0;
  margin-bottom: 2em;
}
.milestone-slider {
  top: 1.55em;
  &-wrap {
    position: relative;
    border-bottom: 3px solid $primary;
    margin-bottom: 8%;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.55em;
      background: $primary;
      width: 1em;
      height: 1em;
      border-radius: 50%;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  .item {
    padding: 0 0.25em;
    &::after {
      content: '';
      display: block;
      border: 3px solid $primary;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      background: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 0 auto 0.5em;
    }
  }
}
.milestone-card {
  border: 0;
  border-bottom: 2.5em solid $primary;
  border-radius: 1.5em;
  box-shadow: 0px 1px 0.75em rgba(0, 0, 0, 0.15);
  margin: 0.5em 1em 3em;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1.5em 1em 0 1em;
    border-color: $primary transparent transparent transparent;
    margin-top: 2.25em;
    margin-left: -1em;
  }
  .card-header {
    background: none;
    border-bottom: 0;
    color: $primary;
    font-weight: 700;
    padding: 1.75em 1em 0;
    span {
      display: inline-block;
      font-size: 3em;
      line-height: 1;
      padding-top: 0.15em;
      border-top: 2px solid $primary;
    }
  }
  .card-body {
    color: $gray38;
    padding: 1.25em 1.5em;
  }
  .card-title {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 1em;
  }
  .card-text {
    max-width: 13.5em;
    min-height: 6em;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(sm) {
  .about-main {
    &.has-bg {
      background-size: 120% auto;
    }
  }
}
@include media-breakpoint-up(md) {
  .about {
    &-content {
      padding-top: 3em;
      padding-bottom: 2em;
    }
    &-title {
      line-height: 1.33;
    }
    &-desc {
      line-height: 2;
    }
  }
  .about-card {
    max-width: 48em;
    margin-bottom: 2.5em;
    .card-body {
      padding: 1.75em 2em 1.25em;
    }
  }
}
@include media-breakpoint-up(lg) {
  .about-main {
    &.has-bg {
      background-size: 100% auto;
    }
    .container {
      width: 90%;
    }
  }
  .about-card {
    .card-body {
      padding-top: 2em;
    }
    .card-title {
      margin-bottom: 1em;
    }
  }
}
@include media-breakpoint-up(xl) {
  .about-content,
  .about-card {
    font-size: 1.25em;
  }
  .milestone-slider {
    &-wrap {
      margin-left: -3em;
      margin-right: -3em;
    }
  }
}
@include media-breakpoint-up(xxl) {
}