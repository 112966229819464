.header {
  .container {
    max-width: none;
    padding: 0 ($grid-gutter-width * 0.5);
  }
  .lang-dropdown {
    position: fixed;
    right: 4.5em;
    top: 1.4em;
    border: 3px solid $white;
    border-radius: 1.5em;
    color: $white;
    .header-fixed & {
      color: $gray62;
      border-color: $gray62;
    }
    .dropdown-toggle {
      padding: 6px;
      line-height: 1;
      color: inherit;
      .ic {
        font-size: 24px;
      }
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .dropdown-menu {
      margin-top: 0;
      min-width: 0;
      position: static;
      background: none;
      text-align: center;
      white-space: nowrap;
      border: 0;
      padding: 7px 0 7px 7px;
      font-size: 0.85em;
    }
    .dropdown-item {
      display: inline-block;
      width: auto;
      padding: 0;
      color: $primary;
      line-height: 1.75em;
      border-radius: 1.5em;
      background: $white;
      padding: 0 1em;
      margin: 0 0.25em;
      box-shadow: 0px 2px 7px rgba($black, 0.1);
      &:hover {
        color: $black;
      }
      &.active {
        background: $primary;
        color: $white;
      }
    }
    &.show {
      background: rgba($black,0.5);
      .header-fixed & {
        background: rgba($white,0.8);
      }
    }
  }
}
.navbar {
  border-top: 5px solid $primary;
  padding: 0;
  .header-fixed & {
    background: $white;
    box-shadow: 0px 4px 15px rgba($black, 0.15);
  }
}
.navbar-brand {
  padding: 5px 0 10px;
  img {
    height: 57px;
  }
  .logo1 {
    display: block;
    .header-fixed & {
      display: none;
    }
  }
  .logo2 {
    display: none;
    .header-fixed & {
      display: block;
    }
  }
}
.navbar-toggler {
  width: 44px;
  height: 44px;
  position: relative;
  border: 3px solid $white;
  border-radius: 50%;
  margin-top: 3px;
  .header-fixed & {
    border-color: $gray62;
  }
  .ic-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    background: $white;
    width: 20px;
    height: 3px;
    margin: -1.5px 0 0 -10px;
    transition: 0.3s;
    &:nth-child(1) {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(-45deg);
    }
    &:nth-child(3) {
      display: none;
    }
    .header-fixed & {
      background: $gray62;
    }
  }
  &.collapsed {
    .ic-bar {
      &:nth-child(1) {
        transform: translateY(-7px);
      }
      &:nth-child(2) {
        transform: translateY(7px);
      }
      &:nth-child(3) {
        display: block;
      }
    }
  }
}
.navbar-collapse {
  background: $white;
  padding: 1em;
  box-shadow: 0px 4px 15px rgba($black, 0.15);
  .header-fixed & {
    box-shadow: none;
  }
}
.navbar-nav {
  text-align: center;
  .nav-link {
    color: $gray62;
    border-radius: 1.5em;
    &:hover {
      color: $primary;
    }
  }
}

@include media-breakpoint-up(sm) {  
}
@include media-breakpoint-up(md) {
}
@include media-breakpoint-up(lg) {
  .header {
    .lang-dropdown {
      position: absolute;
      right: 1.75em;
      top: 1.8em;
    }
  }
  .navbar-brand {
    padding-top: 10px;
    padding-bottom: 15px;
    img {
      height: 80px;
    }
  }
  .navbar-nav {
    .nav-link {
      color: $white;
      text-shadow: 0px 4px 5px rgba($black, 0.25);
      .navbar-expand-lg & {
        padding-left: 1.2em;
        padding-right: 1.2em;
      }
      .header-fixed & {
        color: $gray62;
      }
      &:hover {
        color: $primary;
        .header-fixed & {
          color: $primary;
        }
      }
      &.active {
        .header-fixed & {
          color: $white;
        }
      }
    }
  }
  .navbar-collapse {
    background: none;
    box-shadow: none;
    padding-right: 5em;
  }
}
@include media-breakpoint-up(xl) {
  .header {
    .container {
      position: relative;
      max-width: map-get($map: $container-max-widths, $key: 'xl');
    }
  }
  .navbar-brand {
    margin-left: -0.2em;
  }
  .navbar-nav {
    .nav-link {
      .navbar-expand-lg & {
        padding-left: 1.5em;
        padding-right: 1.5em;
        margin: 0 0.2em;
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
}