.contact-main {
  background: url('../img/contact_main_bg.png') no-repeat 100% 100%;
  background-size: 50% auto;
  padding-top: 3em;
}
.contact-main-top {
  border-top: 80px solid $primary;
  min-height: 360px;
  text-align: left;
  align-items: start;
  padding-top: 60px;
  .main-top-title {
    small {
      color: $white;
      letter-spacing: 0;
    }
  }
}
.contact {
  &-title {
    color: $gray38;
    @include fz(7,4);
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
  &-desc {
    color: $gray77;
    margin-bottom: 2em;
  }
  &-desc2 {
    color: $primary;
    @include fz(45,40);
    font-weight: 700;
    margin-bottom: 2em;
  }
}
.contact-buttons-row {
  margin-bottom: 4em;
  .btn {
    font-size: 0.9em;
    margin-bottom: 1em;
  }
}
.contact-form {
  .form-foot {
    text-align: right;
    padding-top: 4em;
  }
}

@include media-breakpoint-up(sm) {
  .contact-main-top {
    border-top: 0;
    padding-top: 140px;
    min-height: 440px;
  }
}
@include media-breakpoint-up(md) {
  .contact-main-top {
    position: relative;
    padding-top: percentage($number: 1068 / 1920);
    min-height: 0;
    position: relative;
    .container {
      position: absolute;
      top: percentage($number: 330 / 1068);
      left: 0;
      right: 0;
    }
  }
}
@include media-breakpoint-up(lg) {
  .contact {
    &-desc {
      max-width: 16em;
    }
  }
}
@include media-breakpoint-up(xl) {
  .contact-content {
    font-size: 1.25em;
  }
  .form-group {
    margin-bottom: 2em;
  }
}
@include media-breakpoint-up(xxl) {
}