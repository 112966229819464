.form-control {
  border-width: 0 0 2px;
  padding: 0;
  &:focus {
    box-shadow: none;
  }
}


@include media-breakpoint-up(sm) {  
}
@include media-breakpoint-up(md) {
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
}
@include media-breakpoint-up(xxl) {
}