.index-banner {
  display: flex;
  align-items: center;
  min-height: 480px;
  text-align: center;
  background: 50% 50% no-repeat;
  background-size: cover;
  padding-top: 72px;
  color: $white;
  &-logo {
    img {
      width: 4.5em;
    }
  }
  &-title {
    @include fz(100,40);
    margin: 0.5em 0 0.45em;
    span {
      display: inline-block;
    }
  }
  &-desc {
    @include fz(45,40);
  }
}
.index-sect {
  padding: 3.5em 0;
  text-align: center;
}
.index-about {
  &-bg {
    background: url('../img/index_about_bg.jpg') no-repeat 50% 0;
    background-size: 100% auto;
    padding-top: percentage($number: 500 / 1920);
  }
  &-logo {
    img {
      width: 3.5em;
    }
  }
  &-title {
    color: $gray38;
    @include fz(70,40);
    font-weight: 700;
    letter-spacing: 0.1em;
    margin: 0.75em 0 0.5em;
    span {
      display: inline-block;
    }
  }
}
.index-solution {
  background: url('../img/index_solution_bg.jpg') no-repeat 50% 0;
  background-size: cover;
  padding-bottom: 5em;
  color: $white;
  &-desc {
    margin-bottom: 2.5em;
  }
  &-content {
    border: 3px solid $white;
    border-radius: 1.6em;
    padding: 1.25em;
  }
  &-box {
    position: relative;
    padding: 1em;
    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(196, 196, 196, 0.5);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3), inset 0px 7px 20px rgba(255, 255, 255, 0.6);
      filter: blur(1px);
      border-radius: 1.525em;
      pointer-events: none;
    }
    .pic, .title {
      position: relative;
    }
    .pic {
      padding: 0.5em 0;
      img {
        width: 5em;
        filter: brightness(100);
      }
    }
    .title {
      background: $gray5F;
      @include fz(35,40);
      font-weight: 700;
      border-radius: 2em;
      padding: 1em;
      letter-spacing: 0.05em;
      margin: 0.5em 0 0;
    }
  }
}
.index-service {
  position: relative;
  border-bottom: 1px solid rgba(56, 56, 56, 0.5);
  &::before {
    content: '';
    display: block;
    background: url('../img/index_service_top.png') no-repeat 50% 100%;
    background-size: 100% auto;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin-bottom: -1px;
    padding-top: 6%;
  }
  &-title {
    color: $gray38;
    @include fz(7,4);
    font-weight: 700;
    letter-spacing: 0.05em;
    margin: 0 0 0.5em;
    span {
      display: inline-block;
    }
  }
  &-pic {
    margin-bottom: 1em;
    img {
      width: 12em;
    }
  }
}
.index-team {
  position: relative;
  border-bottom: 1px solid rgba(56, 56, 56, 0.5);
  &-title {
    color: $gray38;
    @include fz(7,4);
    font-weight: 700;
    letter-spacing: 0.05em;
    margin: 0 0 0.5em;
    span {
      display: inline-block;
    }
  }
  &-pic {
    margin-bottom: 1.5em;
  }
}
.index-case {
  padding-bottom: 5em;
  &-slider {
    .item {
      padding: 0 15px;
    }
  }
}

@include media-breakpoint-up(sm) {  
}
@include media-breakpoint-up(md) {
  .index-sect {
    .main-title {
      margin-bottom: 1.5em;
    }
    .container {
      width: 90%;
    }
  }
  .index-service {
    &-content {
      position: relative;
      padding-bottom: 7%;
    }
    &-pic {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 45%;
      img {
        width: auto;
      }
    }
    &-desc,
    &-more {
      width: 45%;
    }
  }
  .index-banner,
  .index-about,
  .index-solution,
  .index-service,
  .index-team {
    &-title {
      line-height: 1.25;
    }
    &-desc {
      line-height: 1.7;
    }
  }
  .index-service,
  .index-team {
    &-desc {
      max-width: 16em;
      display: inline-block;
    }
    .container {
      max-width: 48em;
    }
  }
  .index-solution {
    &-content {
      padding: 2.5em;
    }
  }
}
@include media-breakpoint-up(lg) {
  .index-banner {
    min-height: 640px;
    padding-top: 105px;
  }
  .index-sect {
    padding: 4.5em 0 5em;
  }
  .index-about {
    padding-bottom: 6em;
  }
  .index-solution,
  .index-case {
    padding-bottom: 7.5em;
  }
}
@include media-breakpoint-up(xl) {
  .index-banner,
  .index-sect {
    font-size: 1.25em;
  }
}
@include media-breakpoint-up(xxl) {
  .index-banner {
    min-height: 760px;
  }
}