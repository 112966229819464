.service-content1 {
  letter-spacing: 0.05em;
  background: url('../img/service_sect_bg.png') no-repeat 10% 80%;
  background-size: 80% auto;
  margin-bottom: 4em;
  &-icon {
    width: 8em;
  }
  &-heading {
    color: $gray38;
    font-size: 2.25em;
    font-weight: 700;
    margin-bottom: 0.5em;
  }
  &-heading2 {
    color: $gray62;
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 1em;
  }
  &-pill {
    display: inline-block;
    background: $gray62;
    color: $white;
    border-radius: 2em;
    font-weight: 700;
    letter-spacing: 0.1em;
    padding: 0.5em 1.5em;
    margin-bottom: 1em;
  }
  &-desc {
    line-height: 2;
  }
}
.service-content2 {
  background: $primary-light2;
  padding: 1.5em;
  border-radius: 2.5em;
  text-align: center;
  &-heading {
    color: $primary;
    font-size: 1.6em;
    font-weight: 800;
    letter-spacing: 0.19em;
    padding: 0.25em 0.5em 0.5em;
    margin-bottom: 0.25em;
  }
  &-inner {
    background: $white;
    border-radius: 2em;
    padding: 2em 2em 1em;
  }
}
.service-box {
  padding: 0.5em 0;
  .title {
    background: $primary;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.75em;
    font-weight: 700;
    letter-spacing: 0.05em;
    width: 6.6em;
    height: 6.6em;
    border-radius: 50%;
    padding-top: 0.2em;
    margin: 0 auto;
    strong {
      font-weight: 700;
      font-size: 2.15em;
      letter-spacing: -0.05em;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 2.45em;
      padding-left: 0.05em;
    }
  }
  .desc {
    margin: 1em 0;
  }
}
.service-slogan {
  color: $gray38;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 0.75em 0;
  .divider {
    background: $gray38;
    width: 1.2em;
    height: 2px;
    margin: 0.5em auto;
  }
}

@include media-breakpoint-up(sm) {
  .service-box {
    .desc {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 3em;
    }
  }
}
@include media-breakpoint-up(md) {
  .service-content1 {
    &-icon {
      width: 12em;
    }
  }
  .service-slogan {
    display: flex;
    align-items: center;
    justify-content: center;
    .divider {
      width: 2px;
      height: 1em;
      margin: 0 1em;
    }
  }
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
  .service-content1,
  .service-content2 {
    font-size: 1.25em;
  }
  .service-slogan {
    font-size: 2.25em;
  }
}
@include media-breakpoint-up(xxl) {
}